/* assets/css/fonts.css */

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap');

@font-face {
  font-family: 'ShasenemGoran';
  src: url('../fonts/ShasenemGoran-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.almarai-light {
  font-family: "Almarai", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.almarai-regular {
  font-family: "Almarai", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.almarai-bold {
  font-family: "Almarai", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.almarai-extrabold {
  font-family: "Almarai", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.shasenem-light {
  font-family: 'ShasenemGoran', sans-serif;
  font-weight: 300;
  font-style: normal;
}
