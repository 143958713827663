* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;

}

body {
    font-family: 'Poppins';
    color: #4C4C47;
    background: #f1f1f1;
    height: 100%;

}

.page {
    height: 100vh;
    overflow: scroll;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.ttf');
}

a {
    text-decoration: none;
}

a:hover {
    color: #FAA61A;
}

.MuiFormLabel-root {
    color: #fff !important;
    font-family: 'Poppins' !important;
}

.MuiInputBase-root {
    background: transparent !important;
}

.MuiInputBase-root {
    border: 1px solid #fff;
    border-radius: 9px;
}

.container-padding {
    padding: 0 27em;
}

.container-padding2 {
    padding: 15px 30px;
}

.banner-intro {
    background-image: url('../img/banner.png');
    position: relative;
    background-size: cover;
    background-position: center;
    height: 157px;
    border-radius: 0px 0px 30px 30px;
    padding: 90px 18px 0;
}

.image-container{
    position: absolute;
    left:50%;
    bottom: -10%;
    transform: translateX(-50%);
}
.image-container img{
    border-radius: 50%;  /* Makes the image rounded */
    border: 4px solid white;  /* Adds a white border or stroke */
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
}

.banner-intro .content {
    /* padding: 70px 18px 0; */
    color: #fff;
}
.banner-title{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-title .social-links {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjust the gap between icons as needed */
    margin-top: 10px; /* Adjust the margin top as needed */
}

.banner-title .social-links a {
    color: #30302e;
    font-size: 20px;
}


.banner-title .social-links a img {
    width: 15px;
    position: relative;
    top: -2px;
    filter: brightness(0) invert(1);
}

.banner-title h1 {
    font-size: 25px;
    line-height: 37.5px;
    text-transform: capitalize;
}

.title {
    color: #4C4C47;
    font-size: 20px;
}

.box-img {
    margin-bottom: 0.5rem; /* Adjust as needed */
}

.box-item p {
    margin-bottom: 0.5rem; /* Adjust as needed */
}

.box-img,
.box-img img,
.box-img::after {
    border-radius: 20px;

}

.box-img img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.btn-prev {
    position: fixed;
    top: 25px;
    z-index: 1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.banner-title a {
    color: #30302e;
}

.box-img::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 150px;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

}

.list-cat {
    top: 55px;
    left: 0;
}

.title2 {
    font-size: 25px;

}

.fire-icon {
    border-radius: 25px 0 0 0 !important;
    width: 38px !important;
    height: auto !important;
}

.bottom-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
}

footer {
    padding: 14px 0;
    background-image: url('../img/Subtract.png');
    background-size: cover;
}

footer a,
footer button {
    font-size: 12px;
    color: #fff;
    text-transform: capitalize;
}

.btn-srh {
    border: none;
    background: transparent;
}

.not-active {
    color: red;
}

footer a i,
footer button i {
    font-size: 16px;
    display: block !important;
}

.MuiSpeedDial-root {
    position: absolute;
    left: 0;
    text-align: -webkit-center;
    width: 100%;
    bottom: 0;
}

#SpeedDialopenIconexample-actions {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
}

.MuiButtonBase-root[aria-expanded='false'] {
    background: linear-gradient(359.09deg, #F47B20 16.38%, #FAA61A 71.34%);

}

.MuiButtonBase-root[aria-expanded='true'] {
    background: linear-gradient(180deg, #32322E 0%, #3C3C38 100%);
}

#SpeedDialopenIconexample-actions .MuiButtonBase-root {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    height: 133px;
    width: 145px;

}

#SpeedDialopenIconexample-actions .MuiButtonBase-root:nth-child(1) {
    position: absolute;
    left: -85px;
    top: 40px;
    background-image: url('../img/Group\ 1000001198.png');

}

#SpeedDialopenIconexample-actions .MuiButtonBase-root:nth-child(2) {
    background-image: url('../img/Group\ 1000001197.png');
    position: relative;
    top: -30px;
    left: 1px;
}

#SpeedDialopenIconexample-actions .MuiButtonBase-root:nth-child(3) {
    background-image: url('../img/Group\ 1000001196.png');
    position: absolute;
    right: -85px;
    top: 40px;

}

.MuiTooltip-tooltip {
    display: none !important;
}

.search-box input {
    background: #FAFAFA;
    border-radius: 20px;
    height: 45px;
    padding: 10px 13px;
    border-color: transparent;
}

.search-box input:focus {
    box-shadow: none;
    border-color: #FAA61A;
    background: #FAFAFA;
}

.search-box input::placeholder {
    color: #BBBBBB;

}

.btn-search {
    right: 13px;
    top: 7px;
}

.MuiTab-root {
    color: #4C4C47 !important;
    text-transform: capitalize !important;
    font-family: 'Poppins' !important;
    padding: 2px !important; /* Further reduce padding to bring icons closer together */
    font-size: 14px !important; /* Reduce font size for text under icon */
    margin-right: 2px !important; /* Further reduce margin to reduce space between tabs */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MuiTab-root img {
    width: 45px; /* Ensure the icon width remains larger */
    height: 45px; /* Ensure the icon height remains larger */
    object-fit: contain;
    margin-bottom: 2px; /* Further reduce margin for better spacing */
}

.MuiTab-root .MuiTab-wrapper {
    max-width: 60px; /* Define a specific width for text container */
    text-align: center; /* Center the text */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiTab-root.Mui-selected {
    color: #FAA61A !important;
    font-weight: 600 !important;
}

.MuiTabs-indicator {
    display: none !important;
}

.filter-section .MuiFormLabel-root {
    font-family: 'Poppins';
    color: #4C4C47 !important;
}

.filter-section .MuiInputBase-root,
.filter-section .MuiSelect-select {
    box-shadow: 0px 4px 4px rgba(140, 138, 138, 0.1);
    border-radius: 20px !important;
}

.MuiInputBase-root::before,
.MuiInputBase-root::after {
    display: none !important;
}

.MuiSelect-select,
.MuiMenuItem-root {
    padding-bottom: 5px !important;
    font-family: 'Poppins' !important;
    color: #4C4C47 !important;
    font-size: 14px !important;
}

.box-item .box-img img {
    /* height: 170px;
    border-radius: 30px; */
    margin-bottom: 0.1rem; /* Adjust as needed */
    padding-bottom: 1rem !important;
}

.box-item .box-img::after {
    display: none;
}

.box-item {
    color: #4C4C47;
}

/* .box-item:last-child{
    padding-bottom: 7rem;
} */
.new-price {
    font-size: 18px;
    color: #40C3D4;
    padding-right: 20px;
}

.old-price {
    font-size: 12px;
    text-decoration: line-through;
}

.inc {
    background: linear-gradient(180deg, #73EBFC 0%, #40C3D5 100%);
    color: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.btn-min {
    color: #FAA61A;
    background: none;
    border: none;
    width: 35px;
    height: 35px;
}

.num {
    color: #FAA61A;
    font-weight: 700;
    font-size: 18px;
    padding: 0 14px;
}

.note-input .MuiFormLabel-root {
    color: #BBBBBB !important;
}

.note-input {
    background: #FAFAFA;
    border-radius: 10px;
    border: 1px solid #FAA61A; /* Add this line for the stroke */
}

.total {
    border-radius: 20px;
    background: linear-gradient(180deg, #4C4C47 0%, #66665E 100%);
    color: #ffffff;

    padding:  5px;
    min-width: max-content;
}

.co-total {
    text-align: right;
    text-align: -webkit-right;
    text-align: -moz-right;
}

.box-order {
    background: linear-gradient(126.79deg, #309CAB 66.92%, #FAA61A 83.77%);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    padding: 12px 20px 20px;
    color: #fff;
}

.box-order img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
}

.box-order .btn-quan {
    background: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    border: none;
    color: #4C4C47;
}

.btn-order {
    width: 100%;
    border: none;
    background: linear-gradient(180deg, #32322E 0%, #21211E 100%);
    border-radius: 12px 12px 30px 30px;
    padding: 20px 10px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}

.main-slider img {
    border-radius: 20px;
    height: 266px;
    object-fit: cover;
}

.list-about li p {
    color: #4C4C47;
    text-decoration: underline;
    margin-bottom: 0;
}

.list-about li i {
    color: #4C4C47;
    font-size: 14px;
    margin-right: 7px;
}

.about-page .title {
    font-size: 24px; /* Enlarge title more than the rest */
    text-align: center; /* Center align the title */
    font-weight: bold;
}

.about-page .content {
    font-size: 18px; /* Make the font bigger for the rest of the content */
    text-align: justify; /* Justify the text */
}

.about-page .social a {
    font-size: 18px; /* Enlarge the font size of social links */
}

.social i {
    color: #4C4C47;
    font-size: 20px;
}


.slick-dots li.slick-active button:before {
    color: #FAA61A !important;
    opacity: 1 !important;
}

.slick-dots li button:before {
    color: #D9D9D9 !important;
    opacity: 1 !important;
}

.slick-dots li {
    margin: 0 !important;
}

.MuiTabPanel-root {
    padding: 25px 0 10px !important;
}


.order-status {
    position: absolute;
    top: 10px;
    left: 13px;
    z-index: 1;
    background: #FAA61A;
    border-radius: 20px;
    color: #fff;
    padding: 3px 10px;
    font-size: 14px;
    text-transform: capitalize;
}

.box-item {
    padding-bottom: 1rem !important;
}

.box2 {
    padding-bottom: 3rem !important;
}

.lang-select .MuiInputBase-root {
    border-radius: 9px;
}

.lang-select .MuiSelect-select {
    text-align: left;
    color: #fff !important;
    padding: 10px 10px;
}

.lang-select svg {
    color: #fff;
}

.blur-section {
    backdrop-filter: blur(5px);
    height: 100%;

}

.is-sticky {
    position: fixed;
    top: 10px;
    z-index: 100;
    border-radius: 50%;
    background-color: #fff !important;
    width: 30px;
    height: 30px;
    text-align: center;
    box-shadow: 0px 0px 4px 1px rgb(164 157 157 / 44%);
}

.MuiDialogActions-root .MuiButtonBase-root {
    font-family: 'Poppins' !important;
    color: #fff;
    border-radius: 50px;

}

.MuiDialogActions-root .MuiButtonBase-root:first-child {
    background: #FAA61A;
}

.MuiDialogActions-root .MuiButtonBase-root:last-child {
    background: #e24141;

}

/* width */
::-webkit-scrollbar {
    width: 8px;


}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FAA61A;
    border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FAA61A;
}

.footer .row-links .col-3 i {
    font-size: 24px; /* Adjust the size as needed */
    display: block;
    margin: 0 auto;
  }
  
@media (max-width:575.5px) {
    footer .row-links span {
        display: none;
    }

    footer .row-links i {
        padding: 10px 0;
    }

    .select-group {
        display: grid !important;
    }

    .MuiMenuItem-root {
        min-height: auto !important;
    }

    .box-order .row {
        flex-direction: column-reverse;
    }

    .box-order img {
        width: 100%;
        margin-bottom: .5rem;
    }

    .title-order {
        margin-bottom: .5rem !important;
    }
}

@media (max-width:600px) {
    footer {
        padding: 5px 0;
    }

    .title {
        font-size: 17px;
    }
}

@media (max-width:370px) {
    footer {
        padding: 0;
    }
}

.preloder-img {
    top: 250px;
    left: 0;
    width: 100%;
    animation: pulse 2s infinite;
}

.logo-bottom {
    bottom: 20px;
}

.table-screen {
    padding-top: 200px;
    height: 100vh;
}

.banner {
    height: 100vh;
    padding-bottom: 5rem;
}

.table-screen .MuiFormLabel-root {
    color: #4C4C47 !important;
}

.table-screen .MuiInputBase-input {
    border-bottom: 2px solid #4C4C47 !important;
}

.table-screen button,
.button-search {
    background: #FAA61A;
    color: #fff;
    border: none;
    border-radius: 30px;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
}

.button-search {
    width: auto;
    padding: 5px 30px;
}

.social {
    padding-bottom: 7rem;
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.loading-bg {
    position: absolute;
    bottom: 15rem;
}

.active {

    color: #FAA61A;
}

.icon-box {
    /* background: #4C4C47; */
    width: 55px; /* Ensure the box width remains larger */
    height: 55px; /* Ensure the box height remains larger */
    padding: 5px 0; /* Adjust padding */
    border-radius: 50%;
}

.icon-box img {
    /* filter: brightness(0) invert(1); */
    width: 100%; /* Ensure the icon fills the box */
    height: 100%; /* Ensure the icon fills the box */
}

.MuiTab-root.Mui-selected .icon-box {
    background: #FAA61A;
}

.note-section p {
    color: #40C3D4;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 700;

}
.main-box:last-child{
    padding-bottom: 5rem;
}

/* Modern Modal styles */
.styled-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    padding: 30px;
    border-radius: 12px;
    width: 350px;
    max-width: 90%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #4C4C47;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .styled-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 999; /* Ensure it's below the modal */
  }
  
  .modal-logo {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Ensure the height matches the width */
    border-radius: 50%; /* Make the logo circular */
    object-fit: cover; /* Ensure the logo covers the entire area */
    margin-bottom: 20px;
  }
  
  .modal-title {
    font-size: 1.75rem;
    margin-bottom: 20px;
    color: #4C4C47;
  }
  
  .styled-select .MuiInputBase-root {
    border-radius: 8px;
    color: #4C4C47;
  }
  
  .styled-menu-item {
    font-size: 1rem;
    color: #4C4C47;
  }
  
  .styled-save-button {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #FAA61A;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .styled-save-button:hover {
    background-color: #F47B20;
  }
  
  .styled-form-control {
    width: 100%;
  }
  